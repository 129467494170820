import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'theme-ui';
import { maxBy } from 'lodash';

const HorizontalScroll = ({ children, spacingBetween, ...props }) => {
  const horizontalScrollEl = useRef(null);

  let hasScrolled = false;

  const onWheel = e => {
    e.preventDefault();
    const { deltaX, deltaY } = e;
    const amount = maxBy([deltaX, deltaY], Math.abs);

    if (!hasScrolled) {
      requestAnimationFrame(() => {
        horizontalScrollEl.current.scrollBy(amount, 0);
        hasScrolled = false;
      });

      hasScrolled = true;
    }
  };

  return (
    <Fragment>
      <Flex
        ref={horizontalScrollEl}
        onWheel={e => onWheel(e)}
        sx={{
          '@media (orientation: portrait)': {
            display: 'none',
          },
          '@media (orientation: landscape)': {
            flexDirection: 'row',
            '> *': {
              mx: spacingBetween,
            },
            overflowX: 'scroll',
            overflowY: 'hidden',
          },
        }}
        {...props}
      >
        {children}
      </Flex>
      <Flex
        sx={{
          '@media (orientation: portrait)': {
            flexDirection: 'column',
            overflowX: 'hidden',
            overflowY: 'scroll',
          },
          '@media (orientation: landscape)': {
            display: 'none',
            scrollBehavior: 'smooth',
            '> *': {
              mx: spacingBetween,
            },
          },
        }}
        {...props}
      >
        {children}
      </Flex>
    </Fragment>
  );
};

HorizontalScroll.propTypes = {
  children: PropTypes.node.isRequired,
  spacingBetween: PropTypes.number,
};

HorizontalScroll.defaultProps = {
  spacingBetween: 3,
};

export { HorizontalScroll };
