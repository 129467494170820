/** @jsx jsx */
import { Box, jsx } from 'theme-ui';
import PropTypes from 'prop-types';

import { HEADER_HEIGHT } from '../header';

const Menu = ({ children, width, ...props }) => {
  return (
    <Box
      {...props}
      sx={{
        backgroundColor: 'background',
        width: [null, null, null, width],
        ml: 3,
        mr: [2, null, null, 3],
        px: [2, null, null, 3],
        position: 'fixed',
        right: [0, null, null, 'unset'],
        left: ['unset', null, null, 0],
        top: [3, null, null, HEADER_HEIGHT.lg],
        zIndex: 1,
      }}
    >
      {children}
    </Box>
  );
};

Menu.propTypes = {
  children: PropTypes.node,
  width: PropTypes.string,
};

Menu.defaultProps = {
  children: null,
  width: null,
};

export default Menu;
