/** @jsx jsx */
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

import { HEADER_HEIGHT } from '../header';
import { FOOTER_HEIGHT } from '../footer';

export const PROJECT_TITLE_HEIGHT = '49px';
export const SCROLLBAR_HEIGHT = '4px';
export const FIXED_IMAGE_HEIGHT = `100vh - ${HEADER_HEIGHT.lg} - ${FOOTER_HEIGHT} - ${PROJECT_TITLE_HEIGHT} - ${SCROLLBAR_HEIGHT}`;

const FullHeightGatsbyImage = ({ image }) => (
  <GatsbyImage
    // Adapt each image to the container height and calculate width from height
    sx={{
      '@media (orientation: landscape)': {
        margin: '0 auto',
        height: `calc(${FIXED_IMAGE_HEIGHT})`,
        width: `calc((${FIXED_IMAGE_HEIGHT}) / ${image.height})`,
      },
    }}
    image={image}
  />
);

FullHeightGatsbyImage.propTypes = {
  image: PropTypes.shape({
    layout: PropTypes.string,
    images: PropTypes.shape({
      fallback: PropTypes.shape({
        src: PropTypes.string,
        srcSet: PropTypes.string,
        sizes: PropTypes.string,
      }),
      sources: PropTypes.arrayOf(
        PropTypes.shape({
          srcSet: PropTypes.string,
          type: PropTypes.string,
          sizes: PropTypes.string,
        })
      ),
    }),
    width: PropTypes.number,
    height: PropTypes.number,
  }),
};

export default FullHeightGatsbyImage;
